<template>
  <router-view/>
</template>

<style>
body {
  margin: 0px;
}
@font-face {
  font-family: "Glitch";
  src: local("Glitch"), url('/fonts/Glitch/Glitch.woff2') format('woff2'),
        url('/fonts/Glitch/Glitch.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
